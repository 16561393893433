<template>
  <section class="portfolio">
    <div class="page-header">
      <h3 class="page-title">
        Mes sites
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Sites et applis</a></li>
          <li class="breadcrumb-item active" aria-current="page">Sites</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- bouton ajouter -->
            <div class="row">
              <div class="col-12 mb-4 text-right">
                <router-link to="/sitesApp/site_page">
                  <button type="button" class="btn btn-social-icon btn-outline-facebook"><i class="mdi mdi-plus"></i></button>
                </router-link>
              </div>
            </div>
            <!-- contenu de la page sites et applications -->
            <div class="row">
              <div class="col-12">
                  <div class="row portfolio-grid">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12" v-for="(website, i) in websites" :key="i">
                      <figure class="effect-text-in" @click="showpages(website)">
                        <img src="@/assets/images/import/Colibri_fond_transparent.png" alt="image" />
                        <figcaption>
                          <h4>{{website.name}}</h4>
                          <p>{{website.description}}</p>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require('axios').default
export default {
  name: 'website',
  data () {
    return {
      subMenu: 'sitesApp',
      websites:[],
    }
  },
  methods: {
    getWebsites () {
      axios.get('sites')
      .then ( resWebsite=> {
        console.log(resWebsite)
        this.websites= resWebsite.data.result
      })
      .catch (err => console.log(err))
    },
    showpages(website) {
      this.$router.push(
        {
          name: 'page',
          params: {
            site: website.name,
            ulidsite: website.ulid
          }
        }
      )
    }
  },
  created() {
    this.getWebsites()
  }
}
</script>